





















































































































































































































































































































































































































































































































import Vue from "vue";
import { formatCurrency, formatterNumber, reverseFormatNumber, reverseFormatPercent, formatterPercent } from "@/validator/globalvalidator";
import { ResponseProduct } from "@interface/product.interface";
import { IOption } from "@interface/common.interface";
import { createNamespacedHelpers, mapGetters } from "vuex";
import { ProcessInfoSO, RequestSalesOrderLine, ResponseDetailSalesOrder } from "@interface/sales-order.interface";
import { ResponseInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { UomConversions } from "@interface/uom.interface";
import { Mode } from "@/models/enums/global.enum";
import { SALES_ORDER_STATUS } from "@/models/enums/sales-order.enum";
import { Decimal } from "decimal.js-light";
import { TAX_CALCULATION, TAX_TYPE } from "@enum/tax.enum";
import { accountingTaxService } from "@service/accounting-tax.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { findExclusive, findInclusive } from "@helper/tax-calculator";

type RowSource = "ADD_ROW" | "DETAIL"

interface Row {
  no: number;
  key: number;
  productCode: string | undefined;
  productName: string | undefined;
  productId: string;
  alias: string;
  qtyOrder: number;
  uom: string | undefined;
  location: string | undefined;
  locationName: string;
  cutting: string | undefined;
  finalQty: number;
  batchNumber: string | undefined;
  batchId: string | undefined | null;
  condition: string | undefined;
  bottomPrice: number;
  sellPrice: number;
  discount: {
    percent: number;
    value: number;
  };
  taxValue: number;
  taxId: string | null;
  taxRate: number
  subTotal: number;
  qtyAvailable: number;
  id: string | null;
  isProcessed: boolean;

  batchDTOS: ResponseInventoryLineBatch[]
  fromJC: boolean
  isExceed?: boolean
  processInfo: ProcessInfoSO | null
  rowSource: RowSource
}

const { mapMutations, mapState } = createNamespacedHelpers("salesOrderStore");

export default Vue.extend({
  name: "SalesOrderFormTable",
  components: {
    CSelectCutting: () => import(/*webpackPrefetch: true*/"@/components/shared/select-cutting/CSelectCutting.vue"),
    // SalesOrderTableProcessInfo: () => import(/*webpackPrefetch: true*/"../components/SalesOrderTableProcessInfo.vue"),
    CSelectConditionQuotation: () => import(/*webpackPrefetch: true*/"@/components/shared/select-condition-quotation/CSelectConditionQuotation.vue"),
    CSelectTableLocationBatch: () => import(/*webpackPrefetch: true*/"@/components/shared/select-table-location-batch/CSelectTableLocationBatch.vue"),
    CSelectTaxCode: () => import(/*webpackPrefetch: true*/"@/components/shared/select-tax-code/CSelectTaxCode.vue"),
    SalesOrderTableProductLinesBN: () => import(/*webpackPrefetch: true*/"../components/SalesOrderTableProductLinesBN.vue"),
    CImageViewer: () => import(/*webpackPrefetch: true*/"@/components/shared/image-viewer/CImageViewer.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      TAX_TYPE,
      dtTable: [] as Row[],
      selectedRowKeys: [] as number[],
      vmModal: false,
      dtModal: {
        location: undefined as string | undefined,
        productCode: undefined as string | undefined,
        index: 0,
      },
      vmDiscountInvoice: 0,
      vmSwitchDiscount: false,
      vmModalScale: false,
      defaultTaxValue: 0,
    };
  },
  computed: {
    ...mapState({
      storeReqCreate: (state: any) => state.reqCreate,
      storeDetailSO: (state: any) => state.detailSO,
      storeDeletedLine: (state: any) => state.deletedLines,
      storeProductLines: (state: any) => state.productLines,
    }),
    ...mapGetters({
      getUserPrivilege: "authStore/GET_USER_PRIVILEGES"
    }),
    getProductLineBN() {
      return this.dtTable[this.dtModal.index] ? this.dtTable[this.dtModal.index].batchDTOS : undefined;
    },
    isModeEdit(): boolean {
      return this.$route.meta.mode === Mode.EDIT;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isModeDetail(): boolean {
      return this.$route.meta.mode === Mode.DETAIL;
    },
    isSubmitted(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.SUBMITTED;
    },
    isDraft(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.DRAFT;
    },
    isWaitForApproval(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.WAIT_FOR_APPROVAL;
    },
    isWaitWarehouse(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.WAITING_FOR_WAREHOUSE;
    },
    isDocFromJobCosting(): boolean {
      return !!this.storeDetailSO.jobCostingId;
    },
    defaultExpandRow(): string[] {
      return this.dtTable.map(x => x.key + "");
    },
    findTotal(): number {
      const lines = this.storeProductLines;
      if (!lines || !lines.length) return 0;
      const numbs: number[] = lines.map(x => new Decimal(x.finalQty || 0).times(x.sellPrice || 0).toNumber());
      return numbs.reduce((a, b) => new Decimal(b).plus(a).toNumber(), 0);
    },
    findTotalDiscount(): number {
      const lines = this.storeProductLines;
      if (!lines || !lines.length) return 0;
      const numbs: number[] = lines.map(x => x.discount.value ?? 0);
      return numbs.reduce((a, b) => new Decimal(b).plus(a).toNumber(), 0);
    },
    findGrandTotal(): number {
      const lines = this.storeProductLines;
      if (!lines || !lines.length) return 0;
      const numbs: number[] = lines.map(x => x.subTotal ?? 0);
      return numbs.reduce((a, b) => new Decimal(b).plus(a).toNumber(), 0);
    },
    maxDiscount() {
      if (this.vmSwitchDiscount) return { max: 100 };
      return null;
    },
    findTotalTax(): number {
      return this.storeProductLines.reduce((a, b) => new Decimal(b.taxValue ?? 0).plus(a).toNumber(), 0);
    },
    hasPrivilegeSOWarehouse(): boolean {
      return !!this.getUserPrivilege.find(x => x.key === "sales-order-warehouse" && x.privilege.update);
    },
    hasPrivilegeSO(): boolean {
      return !!this.getUserPrivilege.find(x => x.key === "sales-order" && x.privilege.update);
    },
    isTaxNone(): boolean {
      return this.storeReqCreate.taxCalculation === TAX_CALCULATION.NONE;
    },
    isAdmin(): boolean {
      return this.hasPrivilegeSO as boolean && this.hasPrivilegeSOWarehouse as boolean;
    },
  },
  watch: {
    "storeDetailSO.status": {
      immediate: true,
      handler: "fillTable",
    },
  },
  methods: {
    formatCurrency,
    reverseFormatNumber,
    formatterNumber,
    reverseFormatPercent,
    formatterPercent,
    ...mapMutations({
      storeSetReqCreate: "SET_REQUEST_CREATE",
      storeSetProductLines: "SET_PRODUCT_LINES",
      storeSetDeletedLines: "SET_DELETED_LINE",
    }),
    isUnderBottomPrice(record): boolean {
      return record.sellPrice < record.bottomPrice;
    },
    onSelectTaxCode({meta}, r: Row) {
      r.taxRate = meta.rate;
      if (this.storeReqCreate.taxCalculation === TAX_CALCULATION.EXCLUSIVE) {
        const baseAmount = new Decimal(r.sellPrice || 0).times(r.qtyOrder || 0).minus(r.discount.value || 0).toNumber();
        r.taxValue = findExclusive({baseAmount, taxRate: meta.rate}).amount || 0;
      } else if (this.storeReqCreate.taxCalculation === TAX_CALCULATION.INCLUSIVE) {
        const baseAmount = new Decimal(r.sellPrice || 0).times(r.qtyOrder || 0).toNumber();
        r.taxValue = findInclusive({baseAmount, taxRate: meta.rate}).amount || 0;
      } else {
        r.taxValue = 0;
      }
      this.commitStore();
    },
    onRowSelect(selectedRowKeys: []): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    calcTaxValue(r: Row): number {
      if (this.storeReqCreate.taxCalculation === TAX_CALCULATION.EXCLUSIVE) {
        const baseAmount = new Decimal(r.sellPrice || 0).times(r.qtyOrder || 0).minus(r.discount.value || 0).toNumber();
        r.taxValue = findExclusive({baseAmount, taxRate: r.taxRate}).amount || 0;
      } else if (this.storeReqCreate.taxCalculation === TAX_CALCULATION.INCLUSIVE) {
        const baseAmount = new Decimal(r.sellPrice || 0).times(r.qtyOrder || 0).toNumber();
        r.taxValue = findInclusive({baseAmount, taxRate: r.taxRate}).amount || 0;
      } else {
        r.taxValue = 0;
      }
      return r.taxValue;
    },
    addRow(): void {
      const newRow: Row = {
        no: this.dtTable.length + 1,
        key: this.dtTable.length + 1,
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        productId: "",
        alias: "",
        qtyOrder: 0,
        uom: undefined as string | undefined,
        location: undefined as string | undefined,
        locationName: "",
        cutting: undefined as string | undefined,
        finalQty: 0,
        batchNumber: undefined as string | undefined,
        batchId: undefined as string | undefined,
        condition: undefined as string | undefined,
        bottomPrice: 0,
        sellPrice: 0,
        discount: {
          percent: 0,
          value: 0,
        },
        taxValue: 0,
        taxId: null,
        taxRate: 0,
        subTotal: 0,
        qtyAvailable: 0,
        id: null,
        fromJC: false,
        batchDTOS: [],
        processInfo: {
          cutting: "",
          image: "",
          packQty: "",
          processNote: "",
          size: "",
          sticker: "",
          trayOption: false,
          trayType: "",
          vacuumOption: false,
          vacuumType: "",
        },
        rowSource: "ADD_ROW",
        isProcessed: false,
      };
      const { dtTable } = this;
      this.dtTable = [newRow, ...dtTable];
      this.setNumbering();
      this.commitStore();
    },
    deleteRow(): void {
      const { dtTable } = this;
      const deletedLines = dtTable.filter(x => this.selectedRowKeys.includes(x.key));
      this.storeSetDeletedLines(deletedLines.map(x => x.id));
      const dt = dtTable.filter(x => !this.selectedRowKeys.includes(x.key));
      dt.forEach((x, i) => {
        x.key = i;
        x.no = i + 1;
      });
      this.selectedRowKeys = [];
      this.dtTable = dt;
      this.commitStore();
    },
    commitStore(): void {
      this.storeSetReqCreate({
        ...this.storeReqCreate,
        ...{
          salesOrderLines: this.mapRequest(),
          discountTotalInvoice: this.vmDiscountInvoice,
        }
      });
    },
    mapRequest(): RequestSalesOrderLine[] {
      let req: RequestSalesOrderLine[] = [];
      this.dtTable.forEach(x => {
        req.push({
          alias: x.alias,
          batchId: x.batchId || "",
          bottomPrice: x.bottomPrice,
          condition: x.condition || "",
          cutting: x.cutting || "",
          descriptionName: x.alias,
          discount: x.discount.percent ?? 0,
          id: x.id || null,
          isProcessed: false,
          locationId: "",
          processInfo: {
            cutting: x.processInfo?.cutting || "",
            image: x.processInfo?.image || "",
            packQty: x.processInfo?.packQty || "",
            processNote: x.processInfo?.processNote || "",
            size: x.processInfo?.size || "",
            sticker: x.processInfo?.sticker || "",
            trayOption: x.processInfo?.trayOption || false,
            trayType: x.processInfo?.trayType || "",
            vacuumOption: x.processInfo?.vacuumOption || false,
            vacuumType: x.processInfo?.vacuumType || "",
          },
          productId: x.productId,
          qty: x.qtyOrder,
          qtyAvailable: x.qtyAvailable,
          qtyFinal: x.finalQty,
          sellPrice: x.sellPrice,
          uomId: x.uom || "",
          batchDTOS: x.batchDTOS.map((y, j) => ({
            batchId: y.batchId,
            flag: j === 0, // flag rule multi BN
            locationId: y.warehouseLocationId,
            qtyFinal: y.available
          })),
          taxId: x.taxId || "",
          taxRate: x.taxRate,
        });
      });
      return req;
    },
    onSelectProduct(e: { value: string, meta: IOption<ResponseProduct> }, record: Row, source: "code" | "name"): void {
      if (source === "code") {
        record.productName = e.meta.meta?.name || "";
      } else {
        record.productCode = e.meta.meta?.code || "";
      }
      record.productId = e.meta.meta?.id || "";
      this.commitStore();
    },
    onSelectUom(e: { value: string, meta: IOption<UomConversions> }, record: Row): void {
      record.bottomPrice = e.meta.meta?.bottomPrice || 0;
      this.commitStore();
    },
    onSelectLocation(e: { value: string, meta: ResponseInventoryLineBatch }): void {
      this.dtTable[this.dtModal.index].batchDTOS?.push(e.meta);
      this.commitStore();
    },
    findSubtotal(record: Row): number {
      let sub = new Decimal(0);
      let percent = new Decimal(record.discount.percent || 0);
      sub = new Decimal(record.finalQty || 0).times(record.sellPrice || 0);
      record.discount.value = (sub.times(percent.dividedBy(100))).toNumber();
      record.subTotal = sub.minus(record.discount.value || 0).plus(record.taxValue || 0).toNumber();
      this.storeSetProductLines(this.dtTable);
      return record.subTotal || 0;
    },
    fillTable(): void {
      const data: ResponseDetailSalesOrder = this.storeDetailSO;
      this.vmDiscountInvoice = data.discountTotalInvoice;
      this.dtTable = data.salesOrderLines.map((x, i) => ({
        no: i + 1,
        key: i,
        productCode: x.productCode,
        productName: x.productName,
        productId: x.productId,
        alias: x.alias,
        qtyOrder: x.qty,
        uom: x.uomId,
        uomName: x.uomName,
        location: x.locationId,
        locationName: x.locationName,
        cutting: x.cutting,
        finalQty: x.qtyFinal,
        batch: x.batchNumber,
        batchId: x.batchNumberId,
        batchNumber: x.batchNumber,
        condition: x.condition,
        bottomPrice: x.bottomPrice,
        sellPrice: x.sellPrice,
        discount: {
          percent: x.discount,
          value: new Decimal(x.sellPrice ?? 0).minus(new Decimal(x.sellPrice ?? 0).times(new Decimal(x.discount ?? 0).dividedBy(100))).toNumber(),
        },
        taxValue: x.taxValue,
        taxId: x.taxId || null,
        taxRate: x.taxRate || 0,
        subTotal: x.subTotal,
        qtyAvailable: x.qtyAvailable || 0,
        id: x.id,
        batchDTOS: [],
        processInfo: x.processInfo ? x.processInfo : {
          cutting: "",
          image: "",
          packQty: "",
          processNote: "",
          size: "",
          sticker: "",
          trayOption: false,
          trayType: "",
          vacuumOption: false,
          vacuumType: "",
        },
        fromJC: !!data.jobCostingId,
        rowSource: "DETAIL",
        isProcessed: x.isProcessed,
      }));
    },
    showSelectLocation(record: Row): void {
      const { no } = record;
      this.vmModal = true;
      this.dtModal.productCode = record.productCode;
      this.dtModal.index = no - 1;
    },
    onChangeSwitchDiscount(): void {
      this.vmDiscountInvoice = 0;
    },
    /**
     * find tax rate based on
     * tax calculation
     */
    async onChangeTaxCalculation(): Promise<void> {
      try {
        let search = "";
        const taxCalc = this.storeReqCreate.taxCalculation as TAX_CALCULATION;
        if (taxCalc === TAX_CALCULATION.NONE) {
          search = `taxType~${trimSpaceToUnderscore(TAX_TYPE.VAT_OUT)}_AND_rate~0`;
        } else {
          search = `taxType~${trimSpaceToUnderscore(TAX_TYPE.VAT_OUT)}_AND_rate>0`;
        }
        const { data } = await accountingTaxService.listOfTax({search});
        this.dtTable.forEach(x => {
          x.taxValue = data[0].rate || 0;
        });
        this.defaultTaxValue = data[0].rate || 0;
        this.commitStore();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onSaveSelectLocation({ value }): void {
      this.dtTable[this.dtModal.index].batchDTOS = value;
      this.dtTable[this.dtModal.index].finalQty = this.dtTable[this.dtModal.index].batchDTOS?.reduce((a, b) => new Decimal(b.available ?? 0).plus(a).toNumber(), 0) || 0;
      this.vmModal = false;
      this.commitStore();
    },
    onCloseSelectLocation(): void {
      this.vmModal = false;
    },
    isQtyExceed(record: Row): boolean {
      const total = record.batchDTOS.reduce((a, b) => new Decimal(b.available ?? 0).plus(a).toNumber(), 0);
      record.isExceed = total > record.qtyOrder;
      return record.isExceed;
    },
    setNumbering(): void {
      this.dtTable.forEach((item: Row, i) => {
        item.no = i + 1;
      });
    }
  },
});

