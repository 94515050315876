var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _vm.isDraft || _vm.isModeCreate
                    ? _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "danger", icon: "delete" },
                                  on: { click: _vm.deleteRow }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary", icon: "plus" },
                                  on: { click: _vm.addRow }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_add_row")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-table",
                        {
                          key: "expandTable",
                          attrs: {
                            id: "table1",
                            "data-source": _vm.dtTable,
                            "row-selection": {
                              selectedRowKeys: _vm.selectedRowKeys,
                              onChange: _vm.onRowSelect
                            },
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            scroll: { y: 650 }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "expandedRowRender",
                              fn: function(record) {
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "a-row",
                                      {
                                        attrs: {
                                          gutter: [16, 16],
                                          type: "flex"
                                        }
                                      },
                                      [
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  class: {
                                                    "is-required":
                                                      _vm.isWaitWarehouse
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("lbl_location")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    record.locationName || "-"
                                                  )
                                                )
                                              ])
                                            ]),
                                            (_vm.isAdmin ||
                                              _vm.hasPrivilegeSOWarehouse) &&
                                            _vm.isWaitWarehouse &&
                                              !record.batchId
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c(
                                                      "a-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          icon: "select"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.showSelectLocation(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_select_location"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm.isWaitWarehouse && !record.batchId
                                          ? _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl_list_batch_number"
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "SalesOrderTableProductLinesBN",
                                                  {
                                                    attrs: {
                                                      "data-source":
                                                        record.batchDTOS
                                                    }
                                                  }
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_invoice_description"
                                                  )
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              [
                                                _c("a-textarea", {
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "lbl_type_here"
                                                    ),
                                                    "auto-size": {
                                                      minRows: 1,
                                                      maxRows: 4
                                                    }
                                                  },
                                                  on: {
                                                    change: _vm.commitStore
                                                  },
                                                  model: {
                                                    value: record.alias,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        record,
                                                        "alias",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "record.alias"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_final_qty"))
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("toDecimalQty")(
                                                      record.finalQty || 0
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.isQtyExceed(
                                                      record
                                                    ),
                                                    expression:
                                                      "isQtyExceed(record)"
                                                  }
                                                ],
                                                staticClass: "validate-error"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl_qty_is_exceed"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_cutting"))
                                              )
                                            ]),
                                            record.rowSource === "DETAIL"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        record.processInfo
                                                          .cutting || "-"
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("CSelectCutting", {
                                                      model: {
                                                        value:
                                                          record.processInfo
                                                            .cutting,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            record.processInfo,
                                                            "cutting",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "record.processInfo.cutting"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_qty_pack"))
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.processInfo
                                                      .packQty || "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_process_notes")
                                                )
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.processInfo
                                                      .processNote || "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("lbl_size")))
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.processInfo.size ||
                                                      "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_sticker"))
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.processInfo
                                                      .sticker || "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("lbl_tray")))
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.processInfo
                                                      .trayType || "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_vacuum"))
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.processInfo
                                                      .vacuumType || "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_batch_number")
                                                )
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    record.batchNumber || "-"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_process"))
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              [
                                                _c("a-checkbox", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: record.isProcessed,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        record,
                                                        "isProcessed",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "record.isProcessed"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_image"))
                                              )
                                            ]),
                                            _c("CImageViewer", {
                                              attrs: {
                                                "file-name":
                                                  record.processInfo.image
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            attrs: {
                                              sm: 24,
                                              md: 12,
                                              lg: 6,
                                              xl: 4
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "is-required" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("lbl_condition")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "CSelectConditionQuotation",
                                                  {
                                                    on: {
                                                      "on-select":
                                                        _vm.commitStore
                                                    },
                                                    model: {
                                                      value: record.condition,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          record,
                                                          "condition",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "record.condition"
                                                    }
                                                  }
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm.isAdmin ||
                                        !_vm.hasPrivilegeSOWarehouse
                                          ? [
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    sm: 24,
                                                    md: 12,
                                                    lg: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_bottom_price"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            record.bottomPrice
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    sm: 24,
                                                    md: 12,
                                                    lg: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "is-required"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "lbl_sell_price"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("a-input-number", {
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "lbl_type_here"
                                                          ),
                                                          precision: 2,
                                                          formatter:
                                                            _vm.formatterNumber,
                                                          parser:
                                                            _vm.reverseFormatNumber,
                                                          min: 0
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.commitStore
                                                        },
                                                        model: {
                                                          value:
                                                            record.sellPrice,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              record,
                                                              "sellPrice",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "record.sellPrice"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.isUnderBottomPrice(
                                                            record
                                                          ),
                                                          expression:
                                                            "isUnderBottomPrice(record)"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "validate-warning ant-form-explain"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_validation_sell_price_below_max_bottom_price"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    sm: 24,
                                                    md: 12,
                                                    lg: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lbl_discount") +
                                                          "(%)"
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("a-input-number", {
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "lbl_type_here"
                                                          ),
                                                          precision: 2,
                                                          formatter:
                                                            _vm.formatterPercent,
                                                          parser:
                                                            _vm.reverseFormatPercent,
                                                          min: 0,
                                                          max: 100
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.commitStore
                                                        },
                                                        model: {
                                                          value:
                                                            record.discount
                                                              .percent,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              record.discount,
                                                              "percent",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "record.discount.percent"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    sm: 24,
                                                    md: 12,
                                                    lg: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lbl_tax_code")
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("CSelectTaxCode", {
                                                        attrs: {
                                                          "tax-type":
                                                            _vm.TAX_TYPE
                                                              .VAT_OUT,
                                                          disabled:
                                                            !record.sellPrice ||
                                                            !_vm.storeReqCreate
                                                              .taxCalculation ||
                                                            _vm.isTaxNone
                                                        },
                                                        on: {
                                                          "on-select": function(
                                                            e
                                                          ) {
                                                            return _vm.onSelectTaxCode(
                                                              e,
                                                              record
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value: record.taxId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              record,
                                                              "taxId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "record.taxId"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    sm: 24,
                                                    md: 12,
                                                    lg: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lbl_tax_value")
                                                      )
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            _vm.calcTaxValue(
                                                              record
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    sm: 24,
                                                    md: 12,
                                                    lg: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-subtitle-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "lbl_sub_total"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            _vm.findSubtotal(
                                                              record
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "a-table-column",
                            {
                              key: "no",
                              attrs: { width: "70px", "data-index": "no" }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(_vm.$t("lbl_number_short")))]
                              )
                            ]
                          ),
                          _c(
                            "a-table-column",
                            {
                              key: "productCode",
                              attrs: { "data-index": "productCode" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(text, record) {
                                    return [
                                      record.fromJC
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record.productCode || "-"
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _c("CSelectMasterProductCode", {
                                            on: {
                                              "on-select": function(e) {
                                                return _vm.onSelectProduct(
                                                  e,
                                                  record,
                                                  "code"
                                                )
                                              }
                                            },
                                            model: {
                                              value: record.productCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  record,
                                                  "productCode",
                                                  $$v
                                                )
                                              },
                                              expression: "record.productCode"
                                            }
                                          })
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "is-required",
                                  attrs: { slot: "title" },
                                  slot: "title"
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_product_code")))]
                              )
                            ]
                          ),
                          _c(
                            "a-table-column",
                            {
                              key: "productName",
                              attrs: { "data-index": "productName" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(text, record) {
                                    return [
                                      record.fromJC
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record.productName || "-"
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _c("CSelectMasterProductName", {
                                            on: {
                                              "on-select": function(e) {
                                                return _vm.onSelectProduct(
                                                  e,
                                                  record,
                                                  "name"
                                                )
                                              }
                                            },
                                            model: {
                                              value: record.productName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  record,
                                                  "productName",
                                                  $$v
                                                )
                                              },
                                              expression: "record.productName"
                                            }
                                          })
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "is-required",
                                  attrs: { slot: "title" },
                                  slot: "title"
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_product_name")))]
                              )
                            ]
                          ),
                          _c(
                            "a-table-column",
                            {
                              key: "uom",
                              attrs: { "data-index": "uom", width: "120px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(text, record) {
                                    return [
                                      record.fromJC
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(record.uomName || "-")
                                            )
                                          ])
                                        : _c("CSelectUomConv", {
                                            attrs: {
                                              "prop-product-id":
                                                record.productId
                                            },
                                            on: {
                                              "on-select": function(e) {
                                                return _vm.onSelectUom(
                                                  e,
                                                  record
                                                )
                                              }
                                            },
                                            model: {
                                              value: record.uom,
                                              callback: function($$v) {
                                                _vm.$set(record, "uom", $$v)
                                              },
                                              expression: "record.uom"
                                            }
                                          })
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "is-required",
                                  attrs: { slot: "title" },
                                  slot: "title"
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_uom")))]
                              )
                            ]
                          ),
                          _c(
                            "a-table-column",
                            {
                              key: "qtyOrder",
                              attrs: { "data-index": "qtyOrder" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(text, record) {
                                    return [
                                      _c("a-input-number", {
                                        attrs: {
                                          placeholder: _vm.$t("lbl_type_here"),
                                          min: 0,
                                          precision: 2,
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber,
                                          readonly: record.fromJC
                                        },
                                        on: { change: _vm.commitStore },
                                        model: {
                                          value: record.qtyOrder,
                                          callback: function($$v) {
                                            _vm.$set(
                                              record,
                                              "qtyOrder",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "record.qtyOrder"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "is-required",
                                  attrs: { slot: "title" },
                                  slot: "title"
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_qty_order")))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "table",
                  {
                    staticClass: "w-100",
                    attrs: { "aria-describedby": "table summary sales order" }
                  },
                  [
                    _c("thead", [_c("th", { attrs: { colspan: "2" } })]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("lbl_total")))]),
                        _c("td", { staticClass: "table-summary--value" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currency")(_vm.findTotal)) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("lbl_total_tax")))]),
                        _c("td", { staticClass: "table-summary--value" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currency")(_vm.findTotalTax)) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("lbl_total_discount")))
                        ]),
                        _c("td", { staticClass: "table-summary--value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(_vm.findTotalDiscount)
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "text-subtitle-2" }, [
                          _vm._v(" " + _vm._s(_vm.$t("lbl_grand_total")) + " ")
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "text-subtitle-2 table-summary--value"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("currency")(_vm.findGrandTotal)) +
                                " "
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$t("lbl_select_location"),
            width: 820,
            "destroy-on-close": true,
            footer: null,
            closable: false
          },
          model: {
            value: _vm.vmModal,
            callback: function($$v) {
              _vm.vmModal = $$v
            },
            expression: "vmModal"
          }
        },
        [
          _c("CSelectTableLocationBatch", {
            attrs: {
              "prop-product-code": _vm.dtModal.productCode,
              "prop-selected-batch": _vm.getProductLineBN
            },
            on: {
              "on-save": _vm.onSaveSelectLocation,
              "on-close": _vm.onCloseSelectLocation
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }